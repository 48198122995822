import { Component, OnInit, Input } from '@angular/core';
import { Post } from '../models/post.model';
import { DBService } from '../services/db.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnInit {
  @Input() post: Post;
  @Input() authed: boolean;
  constructor(public dbService: DBService) {}

  ngOnInit(): void {}

  getOriginalImageUrl(resizedImageUrl: string): string {
    return resizedImageUrl.replace('resized', 'original');
  }
}
