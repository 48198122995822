import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DBService } from './services/db.service';
import { Post } from './models/post.model';
import { DatePipe } from '@angular/common';
import {
  trigger,
  transition,
  style,
  animate,
  state,
  stagger,
  query,
  animateChild,
} from '@angular/animations';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

// import { setClassMetadata } from '@angular/core/src/r3_symbols';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('dummyAnimation', [
      transition('* => void', [
        query('@*', [animateChild()], { optional: true }),
      ]),
    ]),
    // Main title pre-auth
    trigger('titlePopInOut', [
      state(
        'popIn',
        style({
          transform: 'scale(1)',
          opacity: 1,
        })
      ),
      transition(':enter', [
        style({
          opacity: 0,
          transform: 'scale(0.8)',
        }),
        animate('0.3s .5s ease-out'),
      ]),
      transition(':leave', [
        animate(
          '0.3s .2s ease-in',
          style({
            opacity: 0,
            transform: 'scale(0.8)',
          })
        ),
      ]),
    ]),
    // PIN buttons
    trigger('buttonPopInOut', [
      state(
        'popIn',
        style({
          transform: 'scale(1)',
          opacity: 1,
        })
      ),
      transition(':enter', [
        query('.button', [
          style({
            transform: 'scale(0.5)',
            opacity: 0,
          }),
          stagger(50, animate('.2s 1.1s ease-in')),
        ]),
      ]),
      transition(':leave', [
        query('.button', [
          stagger(
            -20,
            animate(
              '.2s ease-in',
              style({
                transform: 'scale(0.5)',
                opacity: 0,
              })
            )
          ),
        ]),
        // animate(
        //   '.2s ease-in',
        //   style({
        //     transform: 'scale(0)',
        //   })
        // ),
      ]),
    ]),
    // Auth background
    trigger('bgSlideOut', [
      state(
        'slideOut',
        style({
          transform: 'scaleY(1)',
        })
      ),
      transition(':leave', [
        animate(
          '1s cubic-bezier(0.79, 0.005, 0.205, 0.995)',
          style({
            transform: 'scaleY(0)',
          })
        ),
      ]),
    ]),
    // Nav buttons
    trigger('navPopInOut', [
      state(
        'popIn',
        style({
          transform: 'scale(1)',
          opacity: 1,
        })
      ),
      transition(':enter', [
        query('button, span', [
          style({
            transform: 'scale(0.5)',
            opacity: 0,
          }),
          stagger(50, animate('.2s 1.1s ease-in')),
        ]),
      ]),
      transition(':leave', [
        query('.button', [
          stagger(
            -20,
            animate(
              '.2s ease-in',
              style({
                transform: 'scale(0.5)',
                opacity: 0,
              })
            )
          ),
        ]),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  @ViewChild('messageInput') messageInput: ElementRef;
  // title = 'hi-world';
  id = 'none';
  digits = '';
  composerContent = '';
  user = '';
  totalPages = 1;
  currentPage = 1;
  posts: Post[];
  composerVisible = false;
  authed = false;
  form: FormGroup;
  attachedImage: File;
  fileUploadProgress = '';

  constructor(
    private dbService: DBService,
    public dataPipe: DatePipe,
    private formBuilder: FormBuilder
  ) {
    this.posts = [];
  }
  ngOnInit() {
    // this.form = this.formBuilder.group({
    //   attachedImage: [''],
    // });
    this.form = new FormGroup({
      messageContent: new FormControl(),
      attachedImage: new FormControl(),
    });
  }

  public loadPosts() {
    this.authed = true;
    this.dbService
      .loadPosts(this.digits, this.currentPage.toString())
      .subscribe((response) => this.parseJson(response));
  }

  // Parse JSON reponse to Post[]
  public parseJson(json) {
    this.user = json.user;
    this.totalPages = parseInt(json.total_pages, 10);
    const posts = json.posts;
    this.posts = [];
    posts.forEach((post) => {
      this.posts.push(
        new Post(post.content, post.image, post.author, post.creation_time)
      );
    });
  }

  public appendDigit(digit: string) {
    if (this.digits.length <= 5) {
      this.digits += digit;
      // console.log(this.digits);
    }
  }

  public resetDigits() {
    this.digits = '';
  }

  /**
   * New post
   */
  post() {
    if (this.fileUploadProgress !== '') {
      // If there is alreayd an upload in progress,
      // do not respond.
      return;
    }
    this.dbService
      .sendPost(
        this.digits,
        this.form.get('messageContent').value,
        this.attachedImage
      )
      .subscribe((event: any) => {
        if (event.type === 1) {
          // Image is being uploaded
          this.fileUploadProgress = (
            (event.loaded * 100) /
            event.total
          ).toFixed(0);
        } else if (event.type === 4) {
          if (event.status === 200) {
            // Post successfully
            this.loadPosts();
            this.messageInput.nativeElement.value = '';
            this.form.get('messageContent').setValue('');
            this.composerVisible = false;
            this.composerContent = '';
            this.attachedImage = null;
            this.fileUploadProgress = '';
          }
        }
      });
  }
  hideComposer() {
    this.composerVisible = false;
    this.messageInput.nativeElement.value = '';
  }
  showComposer() {
    this.composerVisible = true;
  }
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.attachedImage = event.target.files[0];
    }
  }
  prevPage() {
    this.currentPage -= 1;
    this.loadPosts();
  }
  nextPage() {
    this.currentPage += 1;
    this.loadPosts();
  }
}
