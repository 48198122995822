import { Optional, Input } from '@angular/core';

export class Post {
  constructor(
    public content,
    @Optional() public image,
    @Optional() public author,
    @Optional() public creationTime
  ) {}
}
