<div class="post" [class.s]="post.author == 'sijie'" [class.visible]="authed">
  <p>{{ post.content }}</p>
  <div class="image-container" *ngIf="post.image">
    <a [href]="getOriginalImageUrl(post.image)" target="_blank">
      <img
        [src]="
          this.dbService.DEBUG === true
            ? this.dbService.BASE_URL + post.image
            : post.image
        "
    /></a>
  </div>
  <p class="date">
    {{ post.author }}, {{ post.creationTime | date : "M月d日, yyyy" }}
  </p>
</div>
