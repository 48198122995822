import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Post } from '../models/post.model';

@Injectable({
  providedIn: 'root',
})
export class DBService {
  // Before deploy:
  // Swap out the following two lines
  // public DEBUG = false;
  public DEBUG = true;
  public BASE_URL = 'https://hiangel.me/';
  // public BASE_URL = 'http://192.168.86.246:8000/';
  private loadUrl = this.BASE_URL + 'server/load.php';
  private postUrl = this.BASE_URL + 'server/post.php';

  constructor(private httpClient: HttpClient) {}

  loadPosts(code: string, page: string) {
    const options = {
      params: new HttpParams().set('code', code).set('page', page),
    };
    return this.httpClient.get(this.loadUrl, options);
  }
  sendPost(authCode: string, postContent: string, attachedImage: File) {
    const postData = new FormData();
    postData.append('code', authCode);
    postData.append('content', postContent || '');
    if (attachedImage) {
      postData.append('image', attachedImage, attachedImage.name);
    }
    return this.httpClient.post(this.postUrl, postData, {
      reportProgress: true,
      observe: 'events',
    });
  }
}
